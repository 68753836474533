import { TooltipCustom } from '@services/ui-components';
import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface TextInputProps {
  label: string;
  placeholder: string;
  name: string;
  id: string;
}

const  TextInputWithCounter: React.FC<TextInputProps> = ({
                                                           label,
                                                           placeholder,
                                                           name,
                                                           id
}) => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(field?.value?.length);
  }, [field.value]);

  const normalizeInputValue = (value: string): string => {
    return value.trim().replace(/\s+/g, ' ');
  };

  const handleBlur = () => {
    const normalizedValue = normalizeInputValue(field.value);
    helpers.setValue(normalizedValue);
    helpers.setTouched(true, true);
  };

  return (
    <>
      <Col
        md={2}
        className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-3'>
          {label}&nbsp;<span className='text-danger'>*</span>
        </label>
      </Col>
      <Col md={10}>
        <div className='position-relative'>
          <Field name={name}>
            {({field, meta, form}: FieldProps) => (
              <input
                {...field}
                type='text'
                id={id}
                placeholder={placeholder}
                className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                onBlur={handleBlur}
                maxLength={100}
              />

            )}
          </Field>
          <TooltipCustom placement='right' text={t('These data are displayed in the Online Registration form')}>
            <i className={`bi bi-info-circle position-absolute top-50 end-0 translate-middle-y text-dark me-2 pe-3'
             ${meta.error ? 'd-none' : ''}`}>
            </i>
          </TooltipCustom>
          <ErrorMessage name={name}>
            {msg => <div className='invalid-feedback'>{msg}</div>}
          </ErrorMessage>
        </div>
        <span
          id='countSymbols'
          className='fs-7 text-grey'>
          {charCount}/100
        </span>
      </Col>
    </>
  );
};

export default TextInputWithCounter;
