/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetEmployeesForLogbookDocument = gql`
    query GetEmployeesForLogbook($id: Int, $onlyActive: Boolean, $itemsPerPage: Int, $filterByNameAndPosition: Boolean) {
  user {
    id
    company {
      id
      employees(
        id: $id
        onlyActive: $onlyActive
        itemsPerPage: $itemsPerPage
        filterByNameAndPosition: $filterByNameAndPosition
      ) {
        employees {
          id
          name
          surname
          email
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesForLogbookQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForLogbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForLogbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForLogbookQuery({
 *   variables: {
 *      id: // value for 'id'
 *      onlyActive: // value for 'onlyActive'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filterByNameAndPosition: // value for 'filterByNameAndPosition'
 *   },
 * });
 */
export function useGetEmployeesForLogbookQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>(GetEmployeesForLogbookDocument, options);
      }
export function useGetEmployeesForLogbookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>(GetEmployeesForLogbookDocument, options);
        }
export function useGetEmployeesForLogbookSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>(GetEmployeesForLogbookDocument, options);
        }
export type GetEmployeesForLogbookQueryHookResult = ReturnType<typeof useGetEmployeesForLogbookQuery>;
export type GetEmployeesForLogbookLazyQueryHookResult = ReturnType<typeof useGetEmployeesForLogbookLazyQuery>;
export type GetEmployeesForLogbookSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForLogbookSuspenseQuery>;
export type GetEmployeesForLogbookQueryResult = Apollo.QueryResult<GetEmployeesForLogbookQuery, GetEmployeesForLogbookQueryVariables>;
export const GetServicesForLogbookDocument = gql`
    query GetServicesForLogbook($onlyFromBookings: Boolean, $itemsPerPage: Int) {
  user {
    id
    company {
      id
      services(onlyFromBookings: $onlyFromBookings, itemsPerPage: $itemsPerPage) {
        itemsPerPage
        services {
          id
          name
          serviceCategoryNew {
            id
            name
            parentCategory {
              id
              name
              deleted
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetServicesForLogbookQuery__
 *
 * To run a query within a React component, call `useGetServicesForLogbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesForLogbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesForLogbookQuery({
 *   variables: {
 *      onlyFromBookings: // value for 'onlyFromBookings'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetServicesForLogbookQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>(GetServicesForLogbookDocument, options);
      }
export function useGetServicesForLogbookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>(GetServicesForLogbookDocument, options);
        }
export function useGetServicesForLogbookSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>(GetServicesForLogbookDocument, options);
        }
export type GetServicesForLogbookQueryHookResult = ReturnType<typeof useGetServicesForLogbookQuery>;
export type GetServicesForLogbookLazyQueryHookResult = ReturnType<typeof useGetServicesForLogbookLazyQuery>;
export type GetServicesForLogbookSuspenseQueryHookResult = ReturnType<typeof useGetServicesForLogbookSuspenseQuery>;
export type GetServicesForLogbookQueryResult = Apollo.QueryResult<GetServicesForLogbookQuery, GetServicesForLogbookQueryVariables>;
export const GetEmployeePositionsDocument = gql`
    query GetEmployeePositions($itemsPerPage: Int) {
  user {
    id
    company {
      id
      employeePositions(itemsPerPage: $itemsPerPage) {
        employeePositions {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeePositionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeePositionsQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetEmployeePositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>(GetEmployeePositionsDocument, options);
      }
export function useGetEmployeePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>(GetEmployeePositionsDocument, options);
        }
export function useGetEmployeePositionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>(GetEmployeePositionsDocument, options);
        }
export type GetEmployeePositionsQueryHookResult = ReturnType<typeof useGetEmployeePositionsQuery>;
export type GetEmployeePositionsLazyQueryHookResult = ReturnType<typeof useGetEmployeePositionsLazyQuery>;
export type GetEmployeePositionsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeePositionsSuspenseQuery>;
export type GetEmployeePositionsQueryResult = Apollo.QueryResult<GetEmployeePositionsQuery, GetEmployeePositionsQueryVariables>;
export const GetBookingStatusForLogbookDocument = gql`
    query GetBookingStatusForLogbook {
  bookingStatus {
    id
    name
  }
}
    `;

/**
 * __useGetBookingStatusForLogbookQuery__
 *
 * To run a query within a React component, call `useGetBookingStatusForLogbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingStatusForLogbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingStatusForLogbookQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookingStatusForLogbookQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>(GetBookingStatusForLogbookDocument, options);
      }
export function useGetBookingStatusForLogbookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>(GetBookingStatusForLogbookDocument, options);
        }
export function useGetBookingStatusForLogbookSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>(GetBookingStatusForLogbookDocument, options);
        }
export type GetBookingStatusForLogbookQueryHookResult = ReturnType<typeof useGetBookingStatusForLogbookQuery>;
export type GetBookingStatusForLogbookLazyQueryHookResult = ReturnType<typeof useGetBookingStatusForLogbookLazyQuery>;
export type GetBookingStatusForLogbookSuspenseQueryHookResult = ReturnType<typeof useGetBookingStatusForLogbookSuspenseQuery>;
export type GetBookingStatusForLogbookQueryResult = Apollo.QueryResult<GetBookingStatusForLogbookQuery, GetBookingStatusForLogbookQueryVariables>;
export const GetCurrentEmployeeIdDocument = gql`
    query GetCurrentEmployeeId {
  user {
    id
    employee {
      id
    }
  }
}
    `;

/**
 * __useGetCurrentEmployeeIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentEmployeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentEmployeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentEmployeeIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentEmployeeIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>(GetCurrentEmployeeIdDocument, options);
      }
export function useGetCurrentEmployeeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>(GetCurrentEmployeeIdDocument, options);
        }
export function useGetCurrentEmployeeIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>(GetCurrentEmployeeIdDocument, options);
        }
export type GetCurrentEmployeeIdQueryHookResult = ReturnType<typeof useGetCurrentEmployeeIdQuery>;
export type GetCurrentEmployeeIdLazyQueryHookResult = ReturnType<typeof useGetCurrentEmployeeIdLazyQuery>;
export type GetCurrentEmployeeIdSuspenseQueryHookResult = ReturnType<typeof useGetCurrentEmployeeIdSuspenseQuery>;
export type GetCurrentEmployeeIdQueryResult = Apollo.QueryResult<GetCurrentEmployeeIdQuery, GetCurrentEmployeeIdQueryVariables>;
export const GetBookingsListDocument = gql`
    query GetBookingsList($branchId: Int, $isDeleted: Boolean, $startDate: String, $endDate: String, $statusId: Int, $serviceId: Int) {
  user {
    id
    company {
      id
      branches(id: $branchId, isDeleted: $isDeleted) {
        bookingsList(
          startDate: $startDate
          endDate: $endDate
          statusId: $statusId
          serviceId: $serviceId
        ) {
          date
        }
      }
    }
  }
}
    `;

/**
 * __useGetBookingsListQuery__
 *
 * To run a query within a React component, call `useGetBookingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsListQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      isDeleted: // value for 'isDeleted'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      statusId: // value for 'statusId'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetBookingsListQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingsListQuery, GetBookingsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingsListQuery, GetBookingsListQueryVariables>(GetBookingsListDocument, options);
      }
export function useGetBookingsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingsListQuery, GetBookingsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingsListQuery, GetBookingsListQueryVariables>(GetBookingsListDocument, options);
        }
export function useGetBookingsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBookingsListQuery, GetBookingsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBookingsListQuery, GetBookingsListQueryVariables>(GetBookingsListDocument, options);
        }
export type GetBookingsListQueryHookResult = ReturnType<typeof useGetBookingsListQuery>;
export type GetBookingsListLazyQueryHookResult = ReturnType<typeof useGetBookingsListLazyQuery>;
export type GetBookingsListSuspenseQueryHookResult = ReturnType<typeof useGetBookingsListSuspenseQuery>;
export type GetBookingsListQueryResult = Apollo.QueryResult<GetBookingsListQuery, GetBookingsListQueryVariables>;
export type GetEmployeesForLogbookQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  onlyActive?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterByNameAndPosition?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetEmployeesForLogbookQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null }> | null } | null } | null } | null };

export type GetServicesForLogbookQueryVariables = Types.Exact<{
  onlyFromBookings?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetServicesForLogbookQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, services?: { __typename?: 'ServicePaginated', itemsPerPage?: number | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null }> | null } | null } | null } | null };

export type GetEmployeePositionsQueryVariables = Types.Exact<{
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetEmployeePositionsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, employeePositions?: { __typename?: 'EmployeePositionPaginated', employeePositions?: Array<{ __typename?: 'EmployeePositionProxy', id: number, name: string }> | null } | null } | null } | null };

export type GetBookingStatusForLogbookQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBookingStatusForLogbookQuery = { __typename?: 'Query', bookingStatus: Array<{ __typename?: 'BookingStatusProxy', id: number, name: string }> };

export type GetCurrentEmployeeIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentEmployeeIdQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, employee?: { __typename?: 'EmployeeProxy', id: number } | null } | null };

export type GetBookingsListQueryVariables = Types.Exact<{
  branchId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetBookingsListQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, branches?: Array<{ __typename?: 'BranchProxy', bookingsList?: Array<{ __typename?: 'BookingProxy', date: string }> | null }> | null } | null } | null };
